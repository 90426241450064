/*
    Animation CSS
*/

.in-place {
	opacity: 0;
}

.from-left {
	transform: translateX(-30px);
	opacity: 0;
}

.from-right {
	transform: translateX(30px);
	opacity: 0;
}

.from-top {
	transform: translateY(-30px);
	opacity: 0;
}

.from-bottom {
	transform: translateY(30px);
	opacity: 0;
}
